<template>
    <div class="footer-page">
      <div>
        <p>主办单位：中国企业评价协会 <i class="pd"></i> 电话：010-53343088 (工作日 8:30-17:30)<i class="pd"></i>邮箱：heguishi@heguishi.org.cn</p>
        <p>版权所有：2016-2021Copyright©️企业合规专业委员会 企业合规师等级考试网 京ICP备2021026272号-1</p>
      </div>
      <div>
        <ul class="qrcode-list">
          <li>
            <img src="../../public/img/icon-qrcode1.png" alt="">
            <span>委员会官方公众号关注看考试公开课</span>
          </li>
          <li>
            <img src="../../public/img/icon-qrcode1.png" alt="">
            <span>考试网官方公众号关注查看考试动态</span>
          </li>
        </ul>
      </div>
    </div>
  </template>
  
<script>
    export default {
    data() {
        return {
        };
    },
    mounted() {
    },
    methods: {
    },
};
</script>
<style lang='scss'>
.footer-page {
  padding: 32px 14%;
  display: flex;
  background: #262626;
  align-items: center;
  color: #fff;
  font-size: 12px;
  justify-content: space-between;
  div {
    p {
      line-height: 24px;
    }
  }
}
.pd {
  display: inline-block;
  margin-left: 16px;
}
.qrcode-list {
  display: flex;
  li {
    display: flex;
    flex-direction: column;
    width: 100px;
    margin-right: 20px;
    span {
      display: inline-block;
      margin-top: 8px;
      text-align: center;
      line-height: 18px;
    }
  }
}
</style>
  