<template>
    <div class="home-page-zone">
        <div class="page-commant-title">
            <div>专家团队</div>
            <span></span>
        </div>
        <div class="page-zone-main">
            <div class="zone-left">
                <img src="../../public/img/home-enterprise.png" alt="">
                <div>2019企业合规国际论坛”在京举行</div>
            </div>
            <div class="zone-centen">
                <ul>
                    <li><i></i><span>企业合规师资讯：刑事合规是企业合规迭代发展新样态</span></li>
                    <li><i></i><span>企业合规师资讯：楚雄州检查机关深入推进涉案企业合规改革</span></li>
                    <li><i></i><span>企业合规师资讯：实地考察涉案企业，督促合规真整改</span></li>
                    <li><i></i><span>企业合规师资讯：2022年十大法律监督案例评选揭晓</span></li>
                    <li><i></i><span>企业合规师资讯：合规开创未来 中国企业进入合规新时代</span></li>
                </ul>
            </div>
            <div class="zone-right">
                <div class="zone-ncc">NCC人员注册系统</div>
                <div class="operation-title">认证人员专区</div>
                <div class="operation-item">
                    <ul>
                        <li>
                            <img src="../../public/img/iocn-personnel.png" alt="">
                            <span>人员查询</span>
                        </li>
                        <li>
                            <img src="../../public/img/iocn-people.png" alt="">
                            <span>成为认证专员</span>
                        </li>
                    </ul>
              </div>
              <div class="operation-title">机构专区</div>
                <div class="operation-item">
                    <ul>
                        <li>
                            <img src="../../public/img/icon-mechanism.png" alt="">
                            <span>机构查询</span>
                        </li>
                        <li>
                            <img src="../../public/img/icon-declare.png" alt="">
                            <span>申报入驻</span>
                        </li>
                    </ul>
              </div>
            </div>
        </div>
        <div class="page-contact">
            <div class="btn-contact">联系我们</div>
        </div>
        <div class="page-commant-title">
            <div>友情链接</div>
            <span></span>
        </div>
        <div class="page-friendly-link"></div>
      </div>
    </div>
  </template>
<script>

export default {
    data() {
        return {
        }
    },
    mounted() {
    },
    methods: {
    }
};
</script>
<style lang='scss' scoped>
.page-commant-title {
    display: flex;
    align-items: center;
    margin: 30px 0;
    div {
        width: 152px;
        height: 50px;
        background: #BD3124;
        text-align: center;
        line-height: 50px;
        color: #fff;
    }
    span {
        // width: 80%;
        display: block;
        height: 20px;
        width: 88%;
        background: #BD3124;
        opacity: 0.6;
    }
}
.page-zone-main {
    width: 100%;
    display: flex;
   .zone-left {
        width: 30%;
        img {
            width: 100%;
            height: 198px;
        }
        div {
            background: #FFE4E1;
            padding: 14px;
            margin-top: -4px;
            font-size: 14px;
        }
   }
   .zone-centen {
        width: 45%;
        padding-left: 20px;
        ul {
            border-top: 2px solid #D2D2D2;
            li {
                font-size: 14px;
                padding: 14px 0;
                border-bottom: 1px solid #E7E7E7;
                cursor: pointer;
                position: relative;
                width: 100%;
                overflow:hidden;
                text-overflow: ellipsis;
                white-space:nowrap;
                i {
                    position: absolute;
                    left: 0;
                    top: 18px;
                    width: 12px;
                    height: 14px;
                    display: block;
                    background-image: url('../../public/img/iocn-triangle.png');
                    background-repeat: no-repeat;
                    background-size: 80%;
                }
                span {
                    display: inline;
                    padding-left: 20px;
                    &:hover{
                        color: #BD3124;
                    }
                }
            }
        }
   }
}
.zone-right {
    width: 25%;
    padding-left: 20px;
    .zone-ncc {
        width: 100%;
        background: #951D1D;
        text-align: center;
        height: 40px;
        line-height: 40px;
        color: #fff;
    }
}
.operation-title {
    width: 100%;
    text-align: center;
    color: #FFF;
    height: 40px;
    line-height: 40px;
    background: #BD3124;
    }
    .operation-item {
    ul {
        display: flex;
        padding: 10px 0;
        cursor: pointer;
        li {
        // flex: 1;
        width: 48%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img {
            width: 26px;
            height: 28px;
        }
        span {
            font-size: 14px;
            &:hover{
            color: #BD3124;
            }
        }
        }
    }    
}
.page-contact {
    width: 100%;
    height: 179px;
    background-image: url('../../public/img/bg-contact.png');
    background-repeat: no-repeat;
    background-size:100% 100%;
    position: relative;
    margin-top: 20px;
    // padding-top: 82px;
    .btn-contact {
        width: 114px;
        height: 44px;
        background: #BD3124;
        border-radius: 5px;
        text-align: center;
        line-height: 44px;
        color: #E7E7E7;
        position: absolute;
        left: 50%;
        top: 84px;
        width: 100px;
        margin-left: -50px;
        cursor: pointer;
        transition: all 0.3s;
        &:hover {
            box-shadow: 0 4px 8px 0 #BD3124;/* 鼠标悬浮时盒子出现的阴影 */
            transform: translate(0, -3px);/* 鼠标悬浮时盒子上移10px */
          }
    }
}
.page-friendly-link {
    width: 100%;
    height: 146px;
    background-image: url('../../public/img/bg-friendly-ink.png');
    background-repeat: no-repeat;
    background-size:100% 100%;
}
</style>
    