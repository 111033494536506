<template>
  <div class="home-page">
    <Header></header>
    <!-- <div>测试</div> -->
    <div class="home-page-main">
      <!--  -->
      <div class="main-news">
        <div class="news-box">
          <div class="news-box-left">
            <div class="news-left-title">
              <div v-for="(itm, index) in news" :class="index == newsFalg ? 'active': ''" :key="index" @click="onSelectNav(index)">{{itm}}</div>
            </div>
            <ul class="news-left-list">
              <li @click="onDelict"><i></i> <span>企业合规师资讯：刑事合规是企业合规迭代发展新样态</span></li>
              <li><i></i> <span>企业合规师资讯：楚雄州检查机关深入推进涉案企业合规改革</span></li>
              <li><i></i> <span>企业合规师资讯：实地考察涉案企业，督促合规真整改</span> </li>
              <li><i></i> <span>企业合规师资讯：楚雄州检查机关深入推进涉案企业合规改革</span> </li>
              <li><i></i> <span>企业合规师资讯：实地考察涉案企业，督促合规真整改</span> </li>
              <li><i></i> <span>企业合规师资讯：2022年十大法律监督案例评选揭晓</span> </li>
              <li><i></i> <span>企业合规师资讯：合规开创未来 中国企业进入合规新时代</span> </li>
            </ul>
          </div>
          <div class="news-box-swiper">
            <el-carousel height="310px">
              <el-carousel-item v-for="item in navSwiper" :key="item">
                <img :src="require('/public/img/' + item)" alt="" style="width: 100%;">
              </el-carousel-item>
            </el-carousel>
          </div>
          <div class="news-box-right">
            <div class="operation-box">
              <div class="operation-title">企业合规认证</div>
              <div class="operation-item">
                <ul>
                  <li>
                    <img src="../../public/img/icon-operation.png" alt="">
                    <span>操作指南</span>
                  </li>
                  <li>
                    <img src="../../public/img/icon-process.png" alt="">
                    <span>操作指南</span>
                  </li>
                  <li>
                    <img src="../../public/img/icon-commissioner.png" alt="">
                    <span>联系专员</span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="operation-box">
              <div class="operation-title">企业合规线上检测系统</div>
              <div class="operation-item">
                <ul>
                  <li>
                    <img src="../../public/img/icon-operation.png" alt="">
                    <span>操作指南</span>
                  </li>
                  <li>
                    <img src="../../public/img/icon-download.png" alt="">
                    <span>报告下载</span>
                  </li>
                  <li>
                    <img src="../../public/img/icon-upload.png" alt="">
                    <span>资料上传</span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="operation-box">
              <div class="operation-title">审核、认证</div>
              <div class="operation-item">
                <ul>
                  <li>
                    <img src="../../public/img/icon-train.png" alt="">
                    <span>企业合规培训</span>
                  </li>
                  <li>
                    <img src="../../public/img/icon-team.png" alt="">
                    <span>专家团队</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="main-introduce">
        <div class="introduce-left">
          <div>
            <img src="../../public/img/iocn-authentication.png" alt="">
            <span>认证查询</span>
          </div>
          <div>
            <img src="../../public/img/icon-case.png" alt="">
            <span>认证专员</span>
          </div>
          <div>
            <img src="../../public/img/icon-case.png" alt="">
            <span>典型案例</span>
          </div>
          <div>
            <img src="../../public/img/icon-cooperation.png" alt="">
            <span>对外合作</span>
          </div>
        </div>
        <div class="introduce-right">
          <div class="cqc-box">
            <div class="cqc-title">
              <div>认证介绍</div>
              <span></span>
            </div>
            <div>
              <p style="font-size: 14px; line-height: 25px;">
                <img src="../../public/img/icon-cqc.png" align="left" width="120" hspace="5" vspace="5">
                中国质量认证中心（CQC)是由中国政府批准设立，认证机构批准书编号为001号的质量服务机构i，被多国政府和多个国际权威组织认可，在
                国际舞台上发出中国声音、提供中国方案、增进国际互信。经过三十多年的发展，已经...
              </p>
            </div>
            <div class="btn-see">查看全部</div>
          </div>
          <div class="cqc-box" style="padding-right: 0;">
            <div class="cqc-title">
              <div>公正性声明</div>
              <span></span>
            </div>
            <div>
              <p style="font-size: 14px; line-height: 25px;">
                <img src="../../public/img/icon-cqc.png" align="left" width="120" hspace="5" vspace="5">
                中国质量认证中心（CQC)是由中国政府批准设立，认证机构批准书编号为001号的质量服务机构i，被多国政府和多个国际权威组织认可，在
                国际舞台上发出中国声音、提供中国方案、增进国际互信。经过三十多年的发展，已经...
              </p>
              
            </div>
            <div class="btn-see">查看全部</div>
          </div>
        </div>
      </div>
      <div class="main-swiper-propa">
        <el-carousel height="220px">
          <el-carousel-item v-for="item in advList" :key="item">
            <img :src="require('/public/img/' + item)" alt="" style="width: 100%;">
          </el-carousel-item>
        </el-carousel>
      </div>
      <Policy></Policy>
      <PageZone></PageZone>
      
    </div>
    <FooterPage></FooterPage>
    <router-view></router-view>
  </div>
</template>

<script>
import Header from '../components/header.vue'
import Policy from './home-policy.vue'
import PageZone from './home-zone.vue'
import FooterPage from '../components/footer.vue'
export default {
  data() {
    return {
      news: ['新闻动态', '公告通知'],
      navSwiper: ['home-news03.png', 'home-news02.png', 'home-news01.png'],
      advList: ['home-banner01.png', 'home-banner02.png'],
      newsFalg: 0
    };
  },
  mounted() {
  },
  methods: {
    onSelectNav (idx) {
      this.newsFalg = idx
    },
    onDelict () {
      this.$router.push('/newsDetail');
    }
  },
  components: {
    Header,
    Policy,
    PageZone,
    FooterPage
    // Footer
  }
};
</script>
<style lang='scss'>
.home-page-main {
  padding: 0px 13% 80px 13%;
  .main-news {
    margin-top: 30px;
    .news-box {
      width: 100%;
      display: flex;
      .news-box-left {
        width: 30%;
        .news-left-title {
          display: flex;
          border-bottom: 1px solid #D2D2D2;
          padding-bottom: 7px;
          font-weight: 600;
          div {
            color: #323232;
            cursor: pointer;
            position: relative;
            width: 30%;
            &.active {
              color: #BD3124;
              &::after {
                position: absolute;
                content: '';
                left: 0;
                bottom: -8px;
                width: 66px;
                height: 1px;
                background: #BD3124;
              }
            }
          }
        }
        .news-left-list {
          li {
            font-size: 14px;
            padding: 10px 0;
            border-bottom: 1px solid #E7E7E7;
            cursor: pointer;
            position: relative;
            width: 100%;
            overflow:hidden;
            text-overflow: ellipsis;
            white-space:nowrap;
            span {
              display: inline;
              padding-left: 20px;
              &:hover{
                color: #BD3124;
              }
            }
            i {
              position: absolute;
              left: 0;
              top: 12px;
              width: 12px;
              height: 14px;
              display: block;
              background-image: url('../../public/img/iocn-triangle.png');
              background-repeat: no-repeat;
              background-size: 80%;
            }
          }
        }
      }
      .news-box-swiper {
        width: 45%;
        // padding-left: 6px;
        padding: 0 12px;
      }
      .news-box-right {
        width: 25%;
        .operation-box {
          .operation-title {
            width: 100%;
            text-align: center;
            color: #FFF;
            height: 40px;
            line-height: 40px;
            background: #BD3124;
          }
          .operation-item {
            ul {
              display: flex;
              padding: 10px 0;
              cursor: pointer;
              li {
                // flex: 1;
                width: 33.33%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                img {
                  width: 26px;
                  height: 28px;
                }
                span {
                  font-size: 14px;
                  &:hover{
                    color: #BD3124;
                  }
                }
              }
            }
            
          }
        }
      }
    }
  }
}
.main-introduce {
  display: flex;
  margin-top: 30px;
  margin-bottom: 30px;
  .introduce-left {
    width: 460px;
    display: flex;
    flex-wrap: wrap;
    div {
      background: #BD3124;
      margin-right: 14px;
      margin-bottom: 14px;
      width: 140px;
      height: 140px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      transition: all 0.3s;
      cursor: pointer;
      span {
        color: #FFF;
      }
      &:nth-child(2n) {
        margin-right: 0 !important;
      }
      &:hover {
        box-shadow: 0 4px 8px 0 #BD3124;/* 鼠标悬浮时盒子出现的阴影 */
				transform: translate(0, -3px);/* 鼠标悬浮时盒子上移10px */
      }
    }
  }
  .introduce-right {
    display: flex;
    width: 100%;
    // .cqc-title {}
    .cqc-box {
      width: 49%;
      padding-right: 2%;
      position: relative;
      .cqc-title {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        div {
          width: 136px;
          height: 44px;
          color: #fff;
          text-align: center;
          line-height: 44px;
          background: #BD3124;
        }
        span {
            display: block;
            height: 20px;
            width: 80%;
            background: #BD3124;
            opacity: 0.6;
          }
      }
      .cqc-main {
        font-size: 14px;
        div {
          img {
            padding: 4px 6px 4px 0;
            // width: 80%;
          }
        }
      }
    }
  }
}
.main-swiper-propa {
  width: 100%;
  margin: 30px 0;
}
.btn-see {
  position: absolute;
  left: 0;
  bottom: 14px;
  width: 114px;
  height: 44px;
  background: #BD3124;
  border-radius: 5px;
  text-align: center;
  line-height: 44px;
  color: #fff;
  // margin-top: 85px;
  cursor: pointer;
  transition: all 0.3s;
  &:hover {
    box-shadow: 0 4px 8px 0 #BD3124;/* 鼠标悬浮时盒子出现的阴影 */
    // transform: translate(0, -3px);/* 鼠标悬浮时盒子上移10px */
  }
}

</style>
  