<template>
    <div class="header-page">
      <!-- <div>头部</div> -->
      <div class="header-title">
        <img src="../../public/img/icon-national.png" alt="">
        <div>
            <h1>中国企业合规认证平台</h1>
            <p>认证依据GB/T35770-2022《合规管理体系要求及使用指南》</p>
        </div>
      </div>
      <div class="header-nav">
        <ul>
            <li v-for="(item, index) in nav" :key="index" :class="index == flag ? 'active': ''" @click="onSelectNav(index)">
                <span>{{item}}</span>
            </li>
        </ul>
      </div>
    </div>
  </template>
  
<script>
    export default {
    data() {
        return {
            nav: ['首页', '政策法规', '服务', '新闻动态', '认证查询', '成为认证专员', '关于'],
            flag: 0
        };
    },
    mounted() {
    },
    methods: {
        onSelectNav (idx) {
            this.flag = idx
            this.$router.push('/home');
        }
    },
};
</script>
<style lang='scss'>
.header-page {
    .header-title {
        // padding: ;
        padding: 12px 13%;
        display: flex;
        align-items: center;
        img {
            width: 50px;
            height: 50px;
        }
        div {
            color: #BD3124;
            margin-left: 14px;
            h1 {
                font-size: 20px;
                font-weight: 600;
            }
            p {
                font-size: 12px;
                line-height: 20px;
            }
        }
    }
    .header-nav {
        background: #BD3124;
        ul {
            display: flex;
            padding: 0px 8%;
            li {
                color: #fff;
                flex: 1;
                cursor: pointer;
                position: relative;
                text-align: center;
                // height: 100px;
                padding: 14px 0;
                &:hover {
                        background: #f04637;
                    }
                span {
                    font-size: 14px;
                    padding-bottom: 6px;
                }
                &.active {
                    background: #f04637;
                    span {
                        border-bottom: 1px solid #fff;
                    }
                }
                // &::after {
                //     position: absolute;
                //     content: '';
                //     right: 0;
                //     top: 16px;
                //     width: 1px;
                //     height: 20px;
                //     background: #fff;
                //     opacity: 0.3;
                // }
                // &:last-child::after {
                //     display: none;
                // }
            }
        }
    }
}
</style>
  