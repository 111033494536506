<template>
    <div class="home-page-policy">
        <div class="page-commant-title">
            <div>政策法规</div>
            <span></span>
        </div>
        <div class="main-news">
        <div class="policy-box">
          <div class="policy-box-left">
            <div class="news-left-title">
              <div v-for="(itm, index) in news" :class="index == newsFalg ? 'active': ''" :key="index" @click="onSelectNav(index)">{{itm}}</div>
            </div>
            <ul class="news-left-list">
              <li><i></i> <span>企业合规师资讯：刑事合规是企业合规迭代发展新样态 <span>[2023-01-19]</span></span></li>
              <li><i></i> <span>企业合规师资讯：楚雄州检查机关深入推进涉案企业合规改革 <span>[2023-01-19]</span></span></li>
              <li><i></i> <span>企业合规师资讯：实地考察涉案企业，督促合规真整改 <span>[2023-01-19]</span></span> </li>
              <li><i></i> <span>企业合规师资讯：合规开创未来 中国企业进入合规新时代 <span>[2023-01-19]</span></span> </li>
              <li><i></i> <span>企业合规师资讯：实地考察涉案企业，督促合规真整改 <span>[2023-01-19]</span></span> </li>
            </ul>
          </div>
          <div class="news-box-swiper">
            <el-carousel height="278px">
              <el-carousel-item v-for="item in navSwiper" :key="item">
                <!-- <h3>{{ item }}</h3> -->
                <img :src="require('/public/img/' + item)" alt="" style="width: 100%;">
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
        <div class="page-commant-title">
            <div>企业展示</div>
            <span></span>
        </div>
        <div class="enterprise">
            <div>
                <img src="../../public/img/home-exhibition01.png" alt="">
                <h1>华为投资控股有限公司</h1>
                <p> 一般经营项目是：从事高科技产品的研究、开发、销售、服务；
从事对外投资业务；提供管理、咨询、培训等业务...</p>
            </div>
            <div>
              <img src="../../public/img/home-exhibition02.png" alt="">
                <h1>腾讯控股有限公司</h1>
                <p>腾讯是一家世界领先的互联网科技公司，用创新的产品和服务
提升全球各地人们的生活品质...</p>
            </div>
            <div>
              <img src="../../public/img/home-exhibition03.png" alt="">
                <h1>京东科技集团</h1>
                <p>东科技集团是京东集团旗下专注于以技术为产业服务的业务
子集团，致力于为企业...</p>
            </div>
        </div>
      </div>
    </div>
  </template>
  
<script>

export default {
    data() {
        return {
            news: ['国家法规', '地方/行业规定', '专家解读'],
            navSwiper: ['home-policy01.png', 'home-policy02.png', 'home-policy03.png'],
            newsFalg: '0',
            // enterprise: []
        }
    },
    mounted() {
    },
    methods: {
        onSelectNav (idx) {
            this.newsFalg = idx
        }
    }
};
</script>
<style lang='scss' scoped>
.page-commant-title {
    display: flex;
    align-items: center;
    div {
        width: 152px;
        height: 50px;
        background: #BD3124;
        text-align: center;
        line-height: 50px;
        color: #fff;
    }
    span {
        // width: 80%;
        display: block;
        height: 20px;
        width: 88%;
        background: #BD3124;
        opacity: 0.6;
    }
}
.main-news {
    margin-top: 30px;
    .policy-box {
      width: 100%;
      display: flex;
      justify-content: space-between;
      
      .policy-box-left {
        width: 50%;
        .news-left-title {
          display: flex;
          
          padding-bottom: 7px;
          font-weight: 600;
          width: 100%;
          div {
            color: #323232;
            cursor: pointer;
            position: relative;
            // width: 25%;
            margin-right: 30px;
            padding-bottom: 8px;
            &.active {
              color: #BD3124;
              border-bottom: 1px solid #BD3124;
              // &::after {
              //   position: absolute;
              //   content: '';
              //   left: 0;
              //   bottom: -8px;
              //   width: 66px;
              //   height: 1px;
              //   background: #BD3124;
              // }
            }
          }
        }
        .news-left-list {
          margin-top: -8px;
          border-top: 1px solid #D2D2D2;
          li {
            font-size: 14px;
            padding: 10px 0;
            border-bottom: 1px solid #E7E7E7;
            cursor: pointer;
            position: relative;
            width: 100%;
            overflow:hidden;
            text-overflow: ellipsis;
            white-space:nowrap;
            span {
              display: inline;
              padding-left: 20px;
            //   width: ;
              display: flex;
              justify-content: space-between;
              &:hover{
                color: #BD3124;
              }
            }
            i {
              position: absolute;
              left: 0;
              top: 12px;
              width: 12px;
              height: 14px;
              display: block;
              background-image: url('../../public/img/iocn-triangle.png');
              background-repeat: no-repeat;
              background-size: 80%;
            }
          }
        }
      }
      .news-box-swiper {
        width: 45%;
        // padding-left: 6px;
        padding: 0 8px;
      }
    }
  }
  .enterprise {
    display: flex;
    justify-content: space-between;
    div {
        margin-top: 20px;
        width: 32%;
        cursor: pointer;
        img {
            width: 100%;
            height: 250px;
            transition: all 0.3s;
            &:hover {
               transform:scale(1.05);
            }
        }
        h1 {
            font-size: 14px;
            line-height: 25px;
            margin-top: 4px;
        }
        p {
            width: 100%;
            display: -webkit-box;
            overflow: hidden;
            /*! autoprefixer: off; */     
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            font-size: 14px;
            color: #666666;
        }
    }
  }
</style>
    