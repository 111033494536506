import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/home.vue'
import newsDetail from '../views/newsDetail.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '',
    name: 'home',
    component: HomeView
  },
  {
    path: '/home',
    name: 'home',
    component :HomeView,
  },
  {
    path: '/newsDetail',
    name: 'NewsDetail',
    component: newsDetail
  },
]

const router = new VueRouter({
  mode: 'hash',
  routes
})

export default router
